import React, { useState, useEffect } from 'react';
import SplashScreen from './Components/SplashScreen';
import HomePage from './HomePage';

//import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
  const [isSplashScreenLoading, setIsSplashScreenLoading] = useState(true)

  useEffect(() => {
    // Set 3 second timer for splash screen
    setTimeout(() => {
      setIsSplashScreenLoading(false)
    }, 3000)
  }, []);

  return (
    <div>

      {
        isSplashScreenLoading ? (<SplashScreen />) : <HomePage />
      }
    </div>
  )

}

export default App;