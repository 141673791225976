
const Projects = () => {
    const projects = [
        {
            title: 'Done with Dust',
            slug: 'done-with-dust',
            url: 'donewithdust.com',
            githubUrl: 'https://github.com/holmesjeremiah/done-with-dust',
            description: 'This is a single-page application (SPA) for a company that provides cleaning service in Miami, FL. The website is built using ReactJS and Bootstrap CSS and provides basic information about the company like general information, services, and pricing.',

        },
    ]
    return (
        <div id='projects' style={{ minHeight: '80vh' }}>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '50px' }}>


                <div style={{ border: '2px solid #AAA6C9', marginRight: '10px' }}></div>
                <h2 className='' style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>Projects</h2>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", gap: '50px', margin: '0 25px' }}>

                {
                    projects.map((project, index) => {
                        return (
                            <div id={index} style={{ backgroundColor: '#AAA6C9', maxWidth: '500px', borderRadius: '10px', padding: '25px', margin: '10px' }}>

                                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', gap: '10px' }}>
                                    <div>
                                        <div style={{ marginTop: '10px', textAlign: "center", fontSize: '20px' }}>{project.company}</div>
                                        <div style={{ marginBottom: '10px', textAlign: "center", fontSize: '30px' }}>{project.title}</div>

                                    </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>

                                    <img src={project.logo} style={{ objectFit: 'cover', width: '100%', maxWidth: '300px' }} alt="" />

                                </div>
                                <div>
                                </div>

                                <div style={{ margin: '10px', display: 'flex', justifyContent: 'space-around' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <button onClick={() => window.location.href = project.githubUrl} style={{ backgroundColor: '#211F33', color: "white", padding: '10px 15px', borderRadius: '10px' }}>
                                            Github

                                        </button>
                                    </div>

                                    <div style={{ textAlign: 'center' }}>
                                        <button onClick={() => window.location.href = project.url} style={{ backgroundColor: '#211F33', color: "white", padding: '10px 15px', borderRadius: '10px' }}>

                                            {project.url}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )
                }
            </div>
        </div>
    )
}

export default Projects;