import React, { useEffect, useState } from 'react';
const SplashScreen = () => {
    const [logoStyle, setLogoStyle] = useState({
        boxShadow: '0 0 20px #7871AA',

    })
    useEffect(() => {
        // Set 3 second timer for splash screen
        setTimeout(() => {
            setLogoStyle({
                boxShadow: '0 0 5px #7871AA',
            })
        }, 1600)
    }, []);
    useEffect(() => {
        // Set 3 second timer for splash screen
        setTimeout(() => {
            setLogoStyle({
                boxShadow: '0 0 15px #7871AA',

            })
        }, 2000)
    }, []);
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

            <img style={{ border: '0px', borderRadius: '50%', width: '150px', animation: 'spin 1.5s linear', transformOrigin: '50% 50%', ...logoStyle }} src="/logo.png" alt="" />

            <style jsx>{`
                @keyframes spin {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            `}</style>
        </div>
    );
}

export default SplashScreen;